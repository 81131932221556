<template>
  <div class="page">
    <Navbar :isBack="true" title="gate卡" />
    <div class="main">
      <div class="cell">
        <SvgIcon name="gate" class="cell_icon" :color="$config.primary_color" />
        <div class="cell_input">
          <input type="text" v-model="cardSerial" placeholder="请输入卡号" />
        </div>
      </div>
      <div class="cell">
        <SvgIcon name="gate" class="cell_icon" :color="$config.primary_color" />
        <div class="cell_input">
          <input type="text" v-model="cardCode" placeholder="请输入卡密" />
        </div>
      </div>
      <div class="submit_btn" @click="confirm()">确认</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cardCode: "",
      cardSerial: "",
    };
  },
  methods: {
    confirm() {
      if (this.cardCode && this.cardSerial) {
        let params = {
          game_id: localStorage.getItem("game_id"), //游戏id
          userid: localStorage.getItem("userid"),
          cardCode: this.cardCode, //密码
          cardSerial: this.cardSerial, //短信验证码
        };
        this.$http.post("/apispay/h5pay/cardPay", params).then((res) => {
          if (res.code === 200) {
            this.$router.push("/main/my");
            this.$Msg("成功");
            this.cardCode = "";
            this.cardSerial = "";
          } else {
            this.$Msg(res.msg);
          }
        });
      } else {
        this.$Msg("请先将信息补充完整！");
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0.3rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  font-size: 0.25rem;
}
.cell {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 0 0.3rem;
  width: 100%;
  height: 0.76rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  box-sizing: border-box;
}
.cell_icon {
  margin-right: 0.2rem;
  width: 0.45rem;
  height: 0.45rem;
}
.cell_input {
  flex: 1;
  overflow: hidden;
}
.cell_input input {
  width: 100%;
  height: 0.3rem;
}
.cell_input input::placeholder {
  color: #8d8d8d;
  font-size: 0.25rem;
}
.login_code {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3rem;
  width: 100%;
}
.login_code .cell {
  flex: 1;
  margin: 0;
  width: auto;
}
.login_code_btn {
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  box-sizing: border-box;
}
.submit_btn {
  margin-top: 0.2rem;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
</style>